import './modal-edit-pma.scss';
import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import apiClient from '../../../../utils/apiClient';
import Grid from '@mui/material/Unstable_Grid2';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 518,
  textAlign: 'center',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalEditPma({ open, onClose, currentPma }) {
  const { t } = useTranslation();
  const token = sessionStorage.getItem('token') || null;
  const [phoneError, setPhoneError] = useState('');
  let pmaId = '';
  if (currentPma) {
    pmaId = currentPma.id;
  }

  // Esquema de validación con Joi
  const schema = Joi.object({
    name: Joi.string().required().messages({ 'string.empty': t('alert-name') }),
    last_name: Joi.string().required().messages({ 'string.empty': t('alert-last-name') }),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
        'string.email': t('alert-email-format'),
        'string.empty': t('alert-email'),
      }),
    prefix_phone: Joi.string()
      .pattern(/^\+[0-9]{2,3}$/)
      .required()
      .messages({
        'string.pattern.base': t('alert-prefix'),
        'string.empty': t('alert-prefix'),
      }),
    phone: Joi.string()
      .pattern(/^[0-9]{9}$/)
      .required()
      .messages({
        'string.pattern.base': t('alert-phone-format'),
        'string.empty': t('alert-phone'),
      }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setFocus,
  } = useForm({
    resolver: joiResolver(schema),
    mode: 'all',
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    if (currentPma) {
      setValue('name', currentPma.name || '');
      setValue('last_name', currentPma.last_name || '');
      setValue('email', currentPma.email || '');
      setValue('prefix_phone', currentPma.prefix_phone || '');
      setValue('phone', currentPma.phone || '');
    }
  }, [currentPma, setValue]);

  const onSubmit = async (data) => {
    const fullPhoneNumber = parsePhoneNumberFromString(
      data.prefix_phone + data.phone
    );

    if (!fullPhoneNumber.isValid()) {
      setPhoneError(t('alert-phone-format'));
      setFocus('phone');
      return;
    } else {
      setPhoneError('');
    }

    const url = `${process.env.REACT_APP_API_URL}/pma/personal-settings?pmaId=${pmaId}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const { email, ...updatedData } = data;

    try {
      const response = await apiClient.patch(url, updatedData, config);
      if (response.status === 200) {
        setSnackbarOpen(true);
        onClose();
      }
    } catch (error) {
      console.error('Error al guardar la información del pma:', error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };

  return (
    <>
      <Modal open={open} onClose={onClose} className="edit-pma-modal">
        <Box sx={style}>
          <button
            className="close"
            onClick={() => {
              onClose();
            }}
          >
            <span className="icon-close"></span>
          </button>
          <h2>{t('edit-pma-title')}</h2>

          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={6}>
                <div className="form-control required">
                  <label>{t('common-first-name')}</label>
                  <input
                    type="text"
                    {...register('name')}
                  />
                  {errors.name && <p className="error-message">{errors.name.message}</p>}
                </div>
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <div className="form-control required">
                  <label>{t('common-last-name')}</label>
                  <input
                    type="text"
                    {...register('last_name')}
                  />
                  {errors.last_name && <p className="error-message">{errors.last_name.message}</p>}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={12}>
                <div className="form-control">
                  <label>{t('common-email')}</label>
                  <input
                    type="email"
                    {...register('email')}
                    readOnly
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid xs={12} sm={12} md={3}>
                <div className="form-control required">
                  <label>{t('common-prefix')}</label>
                  <input
                    type="text"
                    {...register('prefix_phone')}
                  />
                  {errors.prefix_phone && <p className="error-message">{errors.prefix_phone.message}</p>}
                </div>
              </Grid>
              <Grid xs={12} sm={12} md={9}>
                <div className="form-control required">
                  <label>{t('common-phone')}</label>
                  <input
                    type="text"
                    {...register('phone')}
                  />
                  {phoneError && (
                    <p className="error-message">{phoneError}</p>
                  )}
                </div>
              </Grid>
            </Grid>

            <div className="actions">
              <button className="btn secondary" onClick={onClose}>
                {t('common-cancel')}
              </button>
              <button className="btn" type="submit">
                {t('common-save')}
              </button>
            </div>
          </form>
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={2000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: '100%' }}
        >
          {t('common-data-saved')}
        </Alert>
      </Snackbar>
    </>
  );
}

export default ModalEditPma;

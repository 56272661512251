import './nominate-hac.scss';
import React, { useEffect, useRef, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import apiClient from '../../../../utils/apiClient';
import Header from '../../../organisms/header/header.js';
import decodeToken from '../../../../utils/decode-token';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import ModalConfirmation from '../../../organisms/role-retailer/modal-confirmation/modal-confirmation.js';
import Input from '../../../atoms/input.js';
import { useForm } from 'react-hook-form';
import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function NominateHac() {
  const [countries, setCountries] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [academicTitles, setAcademicTitles] = useState([]);
  const [open, setOpen] = useState(false);
  const [addressType, setAddressType] = useState('none');
  const [allowCheckbox, setAllowCheckbox] = useState(false);
  const [checkboxError, setCheckboxError] = useState(false);
  const [phoneError, setPhoneError] = useState('');
  const formRef = useRef();
  const navigate = useNavigate();
  const [areaCode, setAreaCode] = useState('');
  const [retailerOptions, setRetailerOptions] = useState([]);
  const [retailerId, setRetailerId] = useState(null); // Estado para retailer_id
  const [defaultRetailerId, setDefaultRetailerId] = useState(null);
  const storedRetailerId = sessionStorage.getItem('filter-retailer');
  const parsedStoredRetailerId = storedRetailerId ? Number(storedRetailerId) : null;

  const token = sessionStorage.getItem('token') || null;
  const role = decodeToken(token).role;
  const salepersonId = decodeToken(token).id;

  const { formState, register, handleSubmit, setFocus, setValue } = useForm({
    mode: 'all',
  });

  const { t } = useTranslation();

  const { errors } = formState;

  // Fetch salesperson details and set retailer options
  useEffect(() => {
    const fetchSalespersonDetails = async () => {
      try {
        const response = await apiClient.get(
          `${process.env.REACT_APP_API_URL}/retailer/retailer-salesperson/${salepersonId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setAreaCode(response.data.area_code);
        const sortedRetailers = response.data.retailerInfo.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setRetailerOptions(sortedRetailers);
        setDefaultRetailerId(response.data.retailerInfo[0].id);

        if (role === 'retailerGM') {
          const selectedRetailer = Number.isInteger(parsedStoredRetailerId) &&
          sortedRetailers.some((retailer) => retailer.id === parsedStoredRetailerId)
            ? parsedStoredRetailerId
            : defaultRetailerId;

          setRetailerId(selectedRetailer);
          setValue('retailer_id', selectedRetailer);
        }
        else {
          setRetailerId(response.data.retailerInfo[0].id)
        }


      } catch (error) {
        console.error('Error al obtener los detalles del vendedor:', error);
        if (error.response) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'), error);
        }
      }
    };

    if (salepersonId) {
      fetchSalespersonDetails();
    }
  }, [salepersonId, token, t, parsedStoredRetailerId, setValue]);

  // Fetch academic titles based on area code
  useEffect(() => {
    const fetchAcademicTitles = async () => {
      if (areaCode) {
        try {
          const response = await apiClient.get(
            `${process.env.REACT_APP_API_URL}/hac/academic-titles?area_code=${areaCode}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setAcademicTitles(response.data);
        } catch (error) {
          console.error('Error al obtener los títulos académicos:', error);
          if (error.response) {
            setSnackbarOpen(true);
            setSnackbarMessage(t('common-internal-server-error'), error);
          }
        }
      }
    };

    fetchAcademicTitles();
  }, [areaCode, token, t]);

  // Fetch countries for country select
  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await apiClient.get(`${process.env.REACT_APP_API_URL}/hac/countries`);
        setCountries(response.data);
      } catch (error) {
        setSnackbarOpen(true);
        setSnackbarMessage(t('common-internal-server-error'), error);
      }
    };

    fetchCountries();
  }, [t]);

  useEffect(() => {
    if (countries.length && areaCode) {
      const country = countries.find((country) => country.area_code === areaCode);
      if (country) {
        setLanguages(country.language || []);
      }
    }
  }, [countries, areaCode]);

  const handleOpen = async () => {
    try {
      if (!allowCheckbox) {
        setCheckboxError(true);
        return;
      } else {
        setCheckboxError(false);
      }

      await handleSubmit((formData) => {
        const schema = Joi.object({
          name: Joi.string().required(),
          last_name: Joi.string().required(),
          middle_name: Joi.string().allow('').optional(),
          title: Joi.string().allow('').optional(),
          preferred_language: Joi.string().allow('').optional(),
          academic_title: Joi.string().allow('').optional(),
          email: Joi.string()
            .email({ tlds: { allow: false } })
            .required(),
          prefix_phone: Joi.string().required(),
          phone: Joi.number().required(),
          retailer_id: Joi.number().optional(),
        });

        const fullPhoneNumber = parsePhoneNumberFromString(
          formData.prefix_phone + formData.phone
        );

        if (!fullPhoneNumber.isValid()) {
          setPhoneError(t('alert-phone-format'));
          setFocus('phone');
          return;
        } else {
          setPhoneError('');
        }

        setOpen(true);
      })();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRetailerChange = (event) => {
    const selectedValue = Number(event.target.value);
    setRetailerId(selectedValue);
    setValue('retailer_id', selectedValue);
  };

  const handleAddressTypeChange = (event) => {
    setAddressType(event.target.value);
    setValue('alias', '');
    setValue('company_name', '');
    setValue('address_line_1', '');
    setValue('address_number', '');
    setValue('address_line_2', '');
    setValue('postal_code', '');
    setValue('area_code', '');
    setValue('city', '');
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const onSubmit = handleSubmit(async () => {
    const {
      alias,
      company_name,
      address_line_1,
      address_number,
      address_line_2,
      postal_code,
      area_code,
      city,
      address_type,
      middle_name,
      retailer_id,
      title,
      preferred_language,
      academic_title,
      ...rest
    } = Object.fromEntries(new FormData(formRef.current).entries());

    let formData = {
      ...rest,
    };

    if (address_type === 'private') {
      formData = {
        ...rest,
        address: [
          {
            alias: alias || ' ',
            address_line_1: address_line_1 || ' ',
            address_number: address_number || ' ',
            address_line_2: address_line_2 || ' ',
            postal_code: postal_code || ' ',
            area_code,
            city: city || ' ',
            address_type,
          },
        ],
      };
    } else if (address_type === 'company') {
      formData = {
        ...rest,
        address: [
          {
            company_name: company_name || ' ',
            address_line_1: address_line_1 || ' ',
            address_number: address_number || ' ',
            address_line_2: address_line_2 || ' ',
            postal_code: postal_code || ' ',
            area_code,
            city: city || ' ',
            address_type,
          },
        ],
      };
    }

    if (role === 'retailerGM') {
      formData.retailer_id = retailer_id;
    } else {
      formData.retailer_id = retailerId;
    }

    if (middle_name) {
      formData.middle_name = middle_name;
    }

    if (preferred_language) {
      formData.preferred_language = preferred_language;
    }

    if (title) {
      formData.title = title;
    }

    if (academic_title) {
      formData.academic_title = academic_title;
    }

    if (address_line_2) {
      formData.address[0].address_line_2 = address_line_2;
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Cache-Control': 'no-cache',
      },
    };

    try {
      const response = await apiClient.post(
        `${process.env.REACT_APP_API_URL}/hac/invite`,
        formData,
        config
      );
      if (response.status === 200) {
        navigate('/retailer/main');
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 413) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('alert-address-too-large'));
        } else if (error.response?.status === 409) {
          setSnackbarOpen(true);
          setSnackbarMessage(t('alert-duplicate'));
          handleClose();
        } else {
          setSnackbarOpen(true);
          setSnackbarMessage(t('common-internal-server-error'), error);
        }
      } else {
        setSnackbarOpen(true);
        setSnackbarMessage(error);
      }
    }
  });

  return (
    <>
      {token && (role === 'retailer' || role === 'retailerGM') ? (
          <div className="App">
            <Header/>
            <main>
              <section className="form nominate-hac">
                <div className="inner">
                  <form ref={formRef}>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(-1);
                      }}
                      className="back-button"
                    >
                      <span className="icon-chevron-left"></span>{' '}
                      {t('common-back')}
                    </Link>
                    <h2>
                      {t('nominate-title')}
                      <small>{t('nominate-subtitle')}</small>
                    </h2>
                    {languages.length > 1 && (
                      <Grid container spacing={3}>

                        <Grid xs={12} sm={6} md={4}>

                          <div className="form-control">
                            <label htmlFor="preferred_language">{t('common-invitation-language')}</label>
                            <select name="preferred_language" id="preferred_language" className="normal">
                              {languages.map((language, index) => (
                                <option key={index} value={language}>
                                  {t(`languages-${language}`)}
                                </option>
                              ))}
                            </select>
                          </div>

                        </Grid>
                      </Grid>
                    )}
                    {role === 'retailerGM' && (
                      <Grid container spacing={3}>
                        <Grid xs={12} sm={12} md={12}>
                          <div className="form-control">
                            <label htmlFor="retailer_id">{t('common-retailer')}</label>
                            <select
                              name="retailer_id"
                              id="retailer_id"
                              className="normal required"
                              {...register('retailer_id')}
                              value={retailerId || ''}
                              onChange={handleRetailerChange}
                            >
                              <option value="null">
                                {t('common-select-retailer')}
                              </option>
                              {retailerOptions.map((retailer) => (
                                <option key={retailer.id} value={retailer.id}>
                                  {retailer.name} ({retailer.city})
                                </option>
                              ))}
                            </select>
                          </div>
                        </Grid>
                      </Grid>
                    )}


                    <Grid container spacing={3}>
                      <Grid xs={6} sm={4} md={2}>
                        <div className="form-control">
                          <label htmlFor="title">{t('common-title')}</label>
                          <select name="title" id="title" className="normal">
                            <option value="Mr">{t('common-title-Mr')}</option>
                            <option value="Mrs">{t('common-title-Mrs')}</option>
                            <option value="Mx">{t('common-title-Mx')}</option>
                          </select>
                        </div>
                      </Grid>
                      {academicTitles.length !== 0 && (
                        <Grid xs={6} sm={4} md={5}>
                          <div className="form-control">
                          <label htmlFor="academic_title">
                              {t('common-academic-title')}
                            </label>
                            <select
                              name="academic_title"
                              id="academic_title"
                              className="normal"
                            >
                              <option key="" value="">
                                {t('common-select-academic-title')}
                              </option>
                              {Array.isArray(academicTitles) &&
                                academicTitles.map((academicTitle) => (
                                  <option
                                    key={academicTitle.sv_value}
                                    value={academicTitle.sv_value}
                                  >
                                    {academicTitle.text}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </Grid>
                      )}



                        <Grid xs={12} sm={8} md={5}>
                          <div className="form-control required">
                            <Input
                              maxLength="50"
                              type="text"
                              id="name"
                              label={t('common-first-name')}
                              error={errors?.name}
                              {...register('name', {
                                validate: (v) => {
                                  const {error} = Joi.string()
                                    .required()
                                    .error(() => new Error(t('alert-name')))
                                    .validate(v);
                                  return error?.message ? error.message : true;
                                },
                              })}
                            />
                          </div>
                        </Grid>
                      </Grid>

                    <Grid container spacing={3}>
                      <Grid xs={12} sm={6} md={5}>
                        <div className="form-control">
                          <label htmlFor="middle_mame">
                            {t('common-middle-name')}
                          </label>
                          <Input
                            maxLength="50"
                            type="text"
                            id="middle_mame"
                            {...register('middle_name', {})}
                          />
                        </div>
                      </Grid>
                      <Grid xs={12} sm={6} md={5}>
                        <div className="form-control required">
                          <Input
                            maxLength="50"
                            type="text"
                            id="last_name"
                            label={t('common-last-name')}
                            error={errors?.last_name}
                            {...register('last_name', {
                              validate: (v) => {
                                const {error} = Joi.string()
                                  .required()
                                  .error(() => new Error(t('alert-last-name')))
                                  .validate(v);
                                return error?.message ? error.message : true;
                              },
                            })}
                          />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                      <Grid xs={12} sm={12} md={6}>
                        <div className="form-control required">
                          <Input
                            maxLength="50"
                            type="text"
                            id="email"
                            label={t('common-email')}
                            error={errors?.email}
                            {...register('email', {
                              validate: (v) => {
                                const {error} = Joi.string()
                                  .email({tlds: {allow: false}})
                                  .required()
                                  .error(() => new Error(t('alert-email')))
                                  .validate(v);
                                return error?.message ? error.message : true;
                              },
                            })}
                          />
                        </div>
                      </Grid>
                      <Grid xs={12} sm={12} md={6}>
                        <div className="form-control required">
                          <Grid container spacing={1}>
                            <Grid xs={4} sm={4} md={4}>
                              <div className="form-control">
                                <Input
                                  type="text"
                                  id="prefix_phone"
                                  placeholder="+xx/+xxx"
                                  label={t('common-prefix')}
                                  error={errors?.prefix_phone}
                                  {...register('prefix_phone', {
                                    validate: (v) => {
                                      const {error} = Joi.string()
                                        .regex(/^\+[0-9]{1,3}$/)
                                        .required()
                                        .error(() => new Error(t('alert-prefix')))
                                        .validate(v);
                                      return error?.message
                                        ? error.message
                                        : true;
                                    },
                                  })}
                                />
                              </div>
                            </Grid>
                            <Grid xs={8} sm={8} md={8}>
                              <div className="form-control required">
                                <Input
                                  type="text"
                                  id="phone"
                                  label={t('common-phone')}
                                  error={errors?.phone}
                                  {...register('phone', {
                                    validate: (v) => {
                                      const {error} = Joi.string()
                                        .required()
                                        .error(() => new Error(t('alert-phone')))
                                        .validate(v);
                                      return error?.message
                                        ? error.message
                                        : true;
                                    },
                                  })}
                                />
                                {phoneError && (
                                  <p className="error-message">{phoneError}</p>
                                )}
                              </div>
                            </Grid>
                          </Grid>
                        </div>
                      </Grid>
                    </Grid>
                    <div className="form-control">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={allowCheckbox}
                            onChange={(e) => {
                              setAllowCheckbox(e.target.checked);
                              setCheckboxError(!e.target.checked);
                            }}
                            style={{
                              borderColor: checkboxError ? 'red' : '',
                              color: checkboxError ? 'red' : '',
                            }}
                          />
                        }
                        label={
                          <span style={{color: checkboxError ? 'red' : ''}}>
                        {t('hac-consents')}
                      </span>
                        }
                      />
                    </div>
                    <p>{t('retailer-nomination-choose-address')}</p>
                    <div className="form-control">
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        onChange={handleAddressTypeChange}
                        defaultValue={'none'}
                      >
                        <FormControlLabel
                          value="none"
                          name="address_type"
                          control={<Radio/>}
                          label={t('common-none')}
                        />
                        <FormControlLabel
                          value="private"
                          name="address_type"
                          control={<Radio/>}
                          label={t('common-private-address')}
                        />
                        <FormControlLabel
                          value="company"
                          name="address_type"
                          control={<Radio/>}
                          label={t('common-company-address')}
                        />
                      </RadioGroup>
                    </div>

                    {addressType === 'private' && (
                      <div>
                        <div className="form-control">
                          <Input
                            maxLength="50"
                            type="text"
                            id="alias_private"
                            placeholder={t('nominate-alias-placeholder')}
                            label={t('common-address-alias')}
                            error={errors?.alias}
                            {...register('alias', {
                              validate: (v) => {
                                const {error} = Joi.string()
                                  .required()
                                  .error(
                                    () => new Error(t('alert-alias-private'))
                                  )
                                  .validate(v);
                                return error?.message ? error.message : true;
                              },
                            })}
                          />
                        </div>
                        <Grid xs={12} sm={6} md={6}>
                          <div className="form-control">
                            <Grid container spacing={1}>
                              <Grid xs={8} sm={8} md={8}>
                                <div className="form-control">
                                  <Input
                                    maxLength="150"
                                    type="text"
                                    id="address_line_1_private"
                                    label={t('common-address-name')}
                                    error={errors?.address_line_1}
                                    {...register('address_line_1', {
                                      validate: (v) => {
                                        const {error} = Joi.string()
                                          .required()
                                          .error(
                                            () =>
                                              new Error(t('alert-street-address'))
                                          )
                                          .validate(v);
                                        return error?.message
                                          ? error.message
                                          : true;
                                      },
                                    })}
                                  />
                                </div>
                              </Grid>
                              <Grid xs={4} sm={4} md={4}>
                                <div className="form-control">
                                  <Input
                                    maxLength="5"
                                    type="text"
                                    id="address_number_private"
                                    label={t('common-address-number')}
                                    error={errors?.address_number}
                                    {...register('address_number', {
                                      validate: (v) => {
                                        const {error} = Joi.string()
                                          .required()
                                          .error(
                                            () =>
                                              new Error(t('alert-address-number'))
                                          )
                                          .validate(v);
                                        return error?.message
                                          ? error.message
                                          : true;
                                      },
                                    })}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <div className="form-control">
                          <label htmlFor="address_line_2">
                            {t('common-address-line-2')}
                          </label>
                          <input
                            maxLength="150"
                            type="text"
                            id="address_line_2_private"
                            {...register('address_line_2')}
                          />
                        </div>
                        <Grid container spacing={3}>
                          <Grid xs={12} sm={1} md={4}>
                            <div className="form-control">
                              <label htmlFor="country">
                                {t('common-country')}
                              </label>
                              <select
                                name="area_code_private"
                                id="country_private"
                                className="normal"
                                error={errors?.area_code}
                                {...register('area_code', {
                                  validate: (v) => {
                                    const {error} = Joi.string()
                                      .required()
                                      .error(() => new Error(t('alert-country')))
                                      .validate(v);
                                    return error?.message ? error.message : true;
                                  },
                                })}
                              >
                                <option key="" value="">
                                  {t('common-select-country')}
                                </option>
                                {countries.map((country) => (
                                  <option
                                    key={country.area_code}
                                    value={country.area_code}
                                  >
                                    {t('countries-' + country.area_code)}
                                  </option>
                                ))}
                              </select>
                              {errors?.area_code && (
                                <span className="input__error">
                              {errors.area_code.message}
                            </span>
                              )}
                            </div>
                          </Grid>
                          <Grid xs={12} sm={4} md={4}>
                            <div className="form-control">
                              <Input
                                maxLength="50"
                                type="text"
                                id="city_private"
                                label={t('common-city')}
                                error={errors?.city}
                                {...register('city', {
                                  validate: (v) => {
                                    const {error} = Joi.string()
                                      .required()
                                      .error(() => new Error(t('alert-city')))
                                      .validate(v);
                                    return error?.message ? error.message : true;
                                  },
                                })}
                              />
                            </div>
                          </Grid>
                          <Grid xs={12} sm={4} md={4}>
                            <div className="form-control">
                              <Input
                                maxLength="50"
                                type="text"
                                id="postal_code_private"
                                label={t('common-zip-cp')}
                                error={errors?.postal_code}
                                {...register('postal_code', {
                                  validate: (v) => {
                                    const {error} = Joi.string()
                                      .required()
                                      .error(() => new Error(t('alert-zip')))
                                      .validate(v);
                                    return error?.message ? error.message : true;
                                  },
                                })}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    )}

                    {addressType === 'company' && (
                      <div>
                        <div className="form-control">
                          <Input
                            maxLength="50"
                            type="text"
                            id="company_name"
                            label={t('common-company-name')}
                            error={errors?.company_name}
                            {...register('company_name', {
                              validate: (v) => {
                                const {error} = Joi.string()
                                  .required()
                                  .error(() => new Error(t('alert-company-name')))
                                  .validate(v);
                                return error?.message ? error.message : true;
                              },
                            })}
                          />
                        </div>
                        <Grid xs={12} sm={6} md={6}>
                          <div className="address_new">
                            <Grid container spacing={1}>
                              <Grid xs={8} sm={8} md={8}>
                                <div className="form-control">
                                  <Input
                                    maxLength="150"
                                    type="text"
                                    id="address_line_1_company"
                                    label={t('common-address-name')}
                                    error={errors?.address_line_1}
                                    {...register('address_line_1', {
                                      validate: (v) => {
                                        const {error} = Joi.string()
                                          .required()
                                          .error(
                                            () =>
                                              new Error(t('alert-street-address'))
                                          )
                                          .validate(v);
                                        return error?.message
                                          ? error.message
                                          : true;
                                      },
                                    })}
                                  />
                                </div>
                              </Grid>
                              <Grid xs={4} sm={4} md={4}>
                                <div className="form-control">
                                  <Input
                                    maxLength="5"
                                    type="text"
                                    id="address_number_company"
                                    label={t('common-address-number')}
                                    error={errors?.address_number}
                                    {...register('address_number', {
                                      validate: (v) => {
                                        const {error} = Joi.string()
                                          .required()
                                          .error(
                                            () =>
                                              new Error(t('alert-address-number'))
                                          )
                                          .validate(v);
                                        return error?.message
                                          ? error.message
                                          : true;
                                      },
                                    })}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                        </Grid>
                        <div className="form-control">
                          <label htmlFor="address_line_2_company">
                            {t('common-address-line-2')}
                          </label>
                          <input
                            maxLength="150"
                            type="text"
                            id="address_line_2_company"
                            {...register('address_line_2')}
                          />
                        </div>
                        <Grid container spacing={3}>
                          <Grid xs={12} sm={1} md={4}>
                            <div className="form-control">
                              <label htmlFor="country_company">
                                {t('common-country')}
                              </label>
                              <select
                                name="area_code_company"
                                id="country_company"
                                className="normal"
                                error={errors?.area_code}
                                {...register('area_code', {
                                  validate: (v) => {
                                    const {error} = Joi.string()
                                      .required()
                                      .error(() => new Error(t('alert-country')))
                                      .validate(v);
                                    return error?.message ? error.message : true;
                                  },
                                })}
                              >
                                <option key="" value="">
                                  {t('common-select-country')}
                                </option>
                                {countries.map((country) => (
                                  <option
                                    key={country.area_code}
                                    value={country.area_code}
                                  >
                                    {t('countries-' + country.area_code)}
                                  </option>
                                ))}
                              </select>
                              {errors?.area_code && (
                                <span className="input__error">
                              {errors.area_code.message}
                            </span>
                              )}
                            </div>
                          </Grid>
                          <Grid xs={12} sm={4} md={4}>
                            <div className="form-control">
                              <Input
                                maxLength="50"
                                type="text"
                                id="city_company"
                                label={t('common-city')}
                                error={errors?.city}
                                {...register('city', {
                                  validate: (v) => {
                                    const {error} = Joi.string()
                                      .required()
                                      .error(() => new Error(t('alert-city')))
                                      .validate(v);
                                    return error?.message ? error.message : true;
                                  },
                                })}
                              />
                            </div>
                          </Grid>
                          <Grid xs={12} sm={4} md={4}>
                            <div className="form-control">
                              <Input
                                maxLength="50"
                                type="text"
                                id="postal_code_company"
                                label={t('common-zip-cp')}
                                error={errors?.postal_code}
                                {...register('postal_code', {
                                  validate: (v) => {
                                    const {error} = Joi.string()
                                      .required()
                                      .error(() => new Error(t('alert-zip')))
                                      .validate(v);
                                    return error?.message ? error.message : true;
                                  },
                                })}
                              />
                            </div>
                          </Grid>
                        </Grid>
                      </div>
                    )}

                    <Grid container spacing={3}>
                      <Grid xs={12} sm={6} md={6}>
                        <button
                          className="btn"
                          type="button"
                          onClick={handleOpen}
                        >
                          {t('retailer-nomination-action')}
                        </button>
                      </Grid>
                    </Grid>

                  </form>
                  {open ? (
                    <ModalConfirmation
                      open={open}
                      handleClose={handleClose}
                      onSubmit={onSubmit}
                      formRef={formRef}
                    />
                  ) : null}
                </div>
              </section>
            </main>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={3000}
              onClose={handleSnackbarClose}
            >
              <Alert severity="error" sx={{width: '100%'}}>
                {snackbarMessage}
              </Alert>
            </Snackbar>
          </div>
        ) :
        (
          <Navigate to="/login"/>
        )
      }
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
      >
        <Alert severity="error" sx={{width: '100%'}}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

export default NominateHac;
